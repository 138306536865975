<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Add Staff</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link to="/admin/staff" class="btn btn-light mb-4 btn-sm">
				Go back
			</router-link>
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Add a New Staff</h4>
							</div>
						</div>
						<div class="card-body">
							<div
								class="alert alert-danger alert-dismissible fade show"
								role="alert"
								v-if="errorMessage"
								>
								{{ errorMessage }}
							</div>
							<form @submit.prevent="addStaff">
								<div class="row mt-4">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> First name </label>
											<input
												v-model.trim="staff.fname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Last name </label>
											<input
												v-model.trim="staff.lname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Email address </label>
											<input
												v-model.trim="staff.email"
												required
												type="email"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Phone </label>
											<input
												v-model.trim="staff.phone"
												required
												type="text"
												class="form-control mb-3"
												placeholder="(___)___-____"
												inputmode="text"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Password </label>
											<input
												v-model.trim="staff.password"
												required
												type="password"
												class="form-control"
												/>
										</div>
									</div> 
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Staff Role </label>
											<select
												class="form-select form-control mb-3"
												v-model.trim="staff.role"
												required
												>
												<option value="super_admin" 	v-if="getAdminType() === 'super_admin'">Super Admin</option>
												<option value="admin">Admin</option>
												<option value="user">User</option>
											</select>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> A/B testing role </label>
											<select
												class="form-select form-control mb-3"
												v-model.trim="staff.ab_testing_role"
												>
												<option value="" 	v-if="getAdminType() === 'super_admin'">A</option>
												<option v-if="getAdminType() === 'super_admin'" value="A">B</option>
												<option v-if="getAdminType() === 'super_admin'" value="B" selected>Non</option>
											</select>
										</div>
									</div>
								</div>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{ processing ? 'Processing...' : 'Add Staff' }}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { getAdminType } from '@/composables/core/permissions/admin'
</script>

<script>
export default {
  data () {
    return {
      staff: {
        fname: '',
        lname: '',
        email: '',
        phone: '',
        password: '',
		ab_testing_role: '',
        role: 'super_admin'
      },
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false
    }
  },
  methods: {
    addStaff () {
      this.processing = true

      this.errorMessage = ''

      this.axios
        .post('/v1/staff/', {
          fname: this.staff.fname,
          lname: this.staff.lname,
          email: this.staff.email,
          phone: this.staff.phone,
          role: this.staff.role,
		  ab_testing_role: this.staff.ab_testing_role == '' ? null : this.staff.ab_testing_role,
          password: this.staff.password
        })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: 'Staff Added',
            text: 'Staff has been added successfully',
            showCloseButton: true
          })

          this.$router.push({
            name: 'ShowStaff',
            params: { staffId: res.data.id }
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
